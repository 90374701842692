<template>
  <section>
    <p> Hier kunt u afspraken met een latere datum overzien; goedkeuren en eventueel bewerken</p>
    <!-- Start buttons for Alt telephone and finished phone numbers -->
    <div class="buttonlist-wrapper">
      <router-link to="/afspraken/afgerond"><button style="margin-right: 20px;" type="button"
          class="btn btn-info mg-0">Afgeronde afspraken</button></router-link>
      <button class="btn btn-info" @click="openAltTelephoneDialog()">Alternatief Tel-Nummer</button>
    </div>
    <br>
    <!-- Start the list -->
    <div class="container-fluid" id="list">
      <div class="row table-wrapper">
        <div class="col-md-12">
          <!-- Datatable is functional by multiple options. We choose for a search field, sortable tables and a pagination -->
          <DataTable :value="appointments" :paginator="true" :rows="1500"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[1500,3000,5000]" responsiveLayout="scroll" v-model:filters="filters"
            :globalFilterFields="['AppointmentNumber','Contractor','CustomerNumber','Description','ReportDate',
             'MeetingDate', 'VideoAppointmentDate', 'VideoAppointmentTime', 'WorkLocation', 'GeneralProblem']"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Zoeken" />
                </span>
              </div>
            </template>
            <!-- Start column field for the list -->
            <Column field="AppointmentNumber" header="WO-nummer" :sortable="true"></Column>
            <Column field="Contractor" header="Debiteur omschrijving" :sortable="true"></Column>
            <Column field="CustomerNumber" header="Nummer klant" :sortable="true"></Column>
            <Column field="Description" header="Omschrijving" :sortable="true"></Column>
            <Column field="DueBy" header="Gereed voor" :sortable="true"></Column>
            <Column field="PlannendWorkDate" header="Datum gepland" :sortable="true">
              <template #body="{ data }">
                {{ formatDate(data.PlannendWorkDate) }}
              </template>
            </Column>
            <Column field="VideoAppointmentDate" header="BelAfspraak - Datum" :sortable="true">
              <template #body="{ data }">
                {{ formatDate(data.VideoAppointmentDate) }}
              </template>
            </Column>
            <Column field="VideoAppointmentTime" header="BelAfspraak - Tijd"></Column>
            <Column field="WorkLocation" header="Plaats" :sortable="true"></Column>
            <Column field="WorkAddress" header="Werkadres" :sortable="true"></Column>
            <Column field="GeneralProblem" header="Notitie" :sortable="true"></Column>
            <!-- Start of communication buttons -->
            <Column header="Communicatie" :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button v-if="slotProps.data['PhoneNumber']" icon="pi pi-whatsapp"
                  class="p-button-rounded p-button-warning mr-2" @click="openWhatsappAppointmentDialog(slotProps)" />
                <Button v-if="slotProps.data['ClientEmail']" icon="pi pi-envelope"
                  class="p-button-rounded p-button-info mr-2" @click="openEmailDialog(slotProps)" />
              </template>
            </Column>
            <!-- Start of CRUD buttons -->
            <Column header="Aanpassen" :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button icon="pi pi-check" class="p-button-rounded p-button-success mr-2"
                  @click="openCompleteAppointmentDialog(slotProps)" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                  @click="openUpdateAppointmentDialog(slotProps)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                  @click="openDeleteAppointmentDialog(slotProps)" />
              </template>
            </Column>
            <template #paginatorstart>
            </template>
            <template #paginatorend>
            </template>
          </DataTable>
        </div>
      </div>
      <!-- Update Dialog -->
      <Dialog v-model:visible="updateAppointmentDialog" :style="{width: '450px'}" header="Afspraak aanpassen"
        :modal="true" class="p-fluid">
        <div class="field">
          <label for="PlannendWorkDate" style="color: white">Datum geplanned</label>
          <Calendar v-model="this.selectedAppointment.PlannendWorkDate" :dateOnly=true dateFormat="dd/mm/yy" />
        </div>
        <div class="field">
          <label for="VideoAppointmentDate" style="color: white">VideoAfspraak Datum</label>
          <Calendar v-model="this.selectedAppointment.VideoAppointmentDate" :dateOnly=true dateFormat="dd/mm/yy" />
        </div>
        <div class="field">
          <label for="VideoAppointmentTime" style="color: white">VideoAfspraak Tijd</label>
          <Calendar v-model="this.selectedAppointment.VideoAppointmentTime" :timeOnly=true />
        </div>
        <div class="field">
          <label style="color: white" for="GeneralProblem">Notities</label>
          <Textarea id="GeneralProblem" v-model="this.selectedAppointment.GeneralProblem" rows="5" cols="20" />
          </div>
        <template #footer>
            <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="updateAppointmentDialog = false"/>
            <Button label="Opslaan" icon="pi pi-check" class="p-button-text" @click="UpdateAppointment(this.selectedAppointment)"/>
        </template>
        {{this.selectedAppointment}}
      </Dialog>
      <!-- Delete dialogs -->
      <Dialog v-model:visible="deleteAppointmentDialog" :style="{width: '450px'}" header="Afspraak verwijderen" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Weet je zeker dat je deze afspraak wilt verwijderen?</span>
        </div>
        <template #footer>
            <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="deleteAppointmentDialog = false"/>
            <Button label="Verwijderen"  icon="pi pi-check" class="p-button-text" @click="deleteAppointment(this.selectedAppointment);" />
        </template>
      </Dialog>
      <!-- Complete appointment Dialog start -->
      <Dialog v-model:visible="completeAppointmentDialog" :style="{width: '450px'}" header="Afspraak afronden" :modal="true">
          <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span>Weet je zeker dat je deze afspraak wilt afronden?</span>
          </div>
          <template #footer>
              <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="completeAppointmentDialog = false"/>
              <Button label="Afronden"  icon="pi pi-check" class="p-button-text" @click="completeMeeting(this.selectedAppointment);" />
          </template>
      </Dialog>
      <!-- Whatsapp Dialog start -->
      <Dialog v-model:visible="whatsappAppointmentDialog" :style="{width: '450px'}" header="Whatsapp versturen" :modal="true">
          <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="this.selectedAppointment.PhoneNumber">Weet je zeker dat je een bericht wilt sturen naar: {{this.selectedAppointment.PhoneNumber}}?</span>
              <span v-else>Geen telefoonnummer gevonden bij deze afspraak.{{this.selectedAppointment.PhoneNumber}} </span>
          </div>
          <template #footer>
              <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="whatsappAppointmentDialog = false"/>
              <Button v-if="this.selectedAppointment.PhoneNumber" label="Versturen" icon="pi pi-check" class="p-button-text" @click="redirectToWhatsapp(this.selectedAppointment.PhoneNumber);" />
          </template>
      </Dialog>
      <!-- AltTelephone Dialog start -->
      <Dialog v-model:visible="altTelephoneDialog" :style="{width: '450px'}" header="Alternatief telefoon nummer" :modal="true">
        <div class="confirmation-content">
            <label for="description" style="color: white">Alternatief telefoonnummer (Mobiel nummer)</label>
            <input type="input" class="form-control" id="altTel" v-model="altTel" />
            <a :href=cleanAltTel(altTel) class="badge badge-success modal-edit-btn" target="_blank"> Contact maken</a>
        </div>
      </Dialog>
      <!-- Email Dialog start -->
      <Dialog v-model:visible="emailDialog" :style="{width: '450px'}" header="Email Versturen" :modal="true">
          <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="this.selectedAppointment.ClientEmail">Weet je zeker dat je een mail wilt sturen naar: <b>{{this.selectedAppointment.ClientEmail}}</b>?</span>
              <span v-else>Geen email gevonden bij deze afspraak. {{this.selectedAppointment}} </span>
          </div>
          <template #footer>
              <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="emailDialog = false"/>
              <Button v-if="this.selectedAppointment.ClientEmail" label="Ja" icon="pi pi-check" class="p-button-text" @click="sendMail(this.selectedAppointment)" />
          </template>
      </Dialog>
    </div>
  </section>
</template>

<script>
  import AppointmentDataService from "../../services/AppointmentDataService";
  import moment from 'moment';
  import {FilterMatchMode} from 'primevue/api';

  export default {
    name: "appointments-list",
    data() {
      return {
        appointments: null,
        editedAppointment: null,
        selectedAppointment: null,
        altTel: '',
        Mail: '',
        isAppointmentModalVisbile: false,
        filters: {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'AppointmentNumber': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'Contractor': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'CustomerNumber': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'RegistrationDate' : {value: null, matchMode: FilterMatchMode.CONTAINS},
            'PlannendWorkDate': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'VideoAppointmentDate': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'VideoAppointmentTime': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'WorkLocation' : {value: null, matchMode: FilterMatchMode.CONTAINS},
            'GeneralProblem' : {value: null, matchMode: FilterMatchMode.CONTAINS},
            'WorkAddress' : {value: null, matchMode: FilterMatchMode.CONTAINS},
        },
        meetingDialog: false,
        deleteAppointmentDialog: false,
        updateAppointmentDialog: false,
        completeAppointmentDialog: false,
        whatsappAppointmentDialog: false,
        emailDialog: false,
        altTelephoneDialog: false,
      };
    },
    methods: {
      cleanData(data) {    
        if (data.StreetNumber == 'null') {
          data.StreetNumber = "";
        }
        if (data.Street == 'null') {
          data.Street = "";
        }
        if (data.Phonenumber != undefined && data.Phonenumber.startsWith("06-")) {
          data.Phonenumber = data.Phonenumber.replace("06-", "+316").replace(/\s/g, "");
        } else if (data.Phonenumber != undefined && data.Phonenumber.startsWith("06")) {
          data.Phonenumber = data.Phonenumber.replace("06", "+316").replace(/\s/g, "");
        }
      },
      handleData(items) {
        let _appointments = [];
        items.forEach(element => {
          let key = element.key;
          let dataVal = element.val();
          // Needed to transform the data, so we can manupilate it 
          // (proxy can't be manupilated or something, not in a way that I know )
          var data = JSON.parse(JSON.stringify(dataVal))
          console.log(data);
          this.cleanData(data);
        
          if (data.Finished != '1') {
            if(data.ReportDate == "Invalid Date") { data.ReportDate = null }
            if(data.VideoAppointment == undefined) { data.VideoAppointment = "0001-01-01 00:00:00.000000" }
            _appointments.push({
              key: key,
              AppointmentNumber: data.AppointmentNumber,
              Contractor: data.Contractor,
              CustomerNumber: data.CustomerNumber,
              Description: data.Description,
              DueBy: data.DueBy,
              Finished: data.Finished,
              GeneralProblem: data.GeneralProblem != "null" ? data.GeneralProblem : "",
              PlannendWorkDate: this.fixSortingDate(data.PlannendWorkDate),
              RegistrationDate: this.fixSortingDate(data.RegistrationDate),
              Status: data.Status,
              TelNo1: data.TelNo1,
              TelNo2: data.TelNo2,
              WorkAddress: data.WorkAddress,
              WorkLocation: data.WorkLocation,
              WorkType: data.WorkType,
              VideoAppointmentDate: this.fixSortingDate(data.VideoAppointment),
              VideoAppointmentTime: moment.unix(data.VideoAppointment).format('HH:mm') != "Invalid date" ? moment.unix(data.VideoAppointment).format('HH:mm') : "",
            });
          }
        });
        this.appointments = _appointments;
      },    

      // Dialog boxes functions
      // altTelephone modal
      openAltTelephoneDialog(){
        this.altTelephoneDialog = true;
      },
      // Whatsapp 
      openWhatsappAppointmentDialog(appointment) {
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.whatsappAppointmentDialog = true;
      },
      // function for whatsapp modal to redirect the user to whatsapp.com with the right phone number
      redirectToWhatsapp(phoneNumber) {
        this.whatsappAppointmentDialog = false;
        if (phoneNumber != null) {
          if (phoneNumber.startsWith("06-")) {
            const cleanTel = phoneNumber.replace("06-", "+316").replace(/\s/g, "");
            // Instead of opening a new tab. This should go to the whatsapp bot. 
            window.open(`http://wa.me/${cleanTel}`)
          } else if (phoneNumber.startsWith("06")) {
            const cleanTel = phoneNumber.replace("06", "+316").replace(/\s/g, "");
            window.open(`http://wa.me/${cleanTel}`)
          } else {
            window.open(`http://wa.me/${phoneNumber.replace(/\s/g, "")}`)
            return 
          }
        }
      },
      formatDate(date){
        // owno
        if(date == "No Meeting" || date == "No video appointment" || date == "No meeting" || date == "Invalid date" || date == undefined || date == "0001-01-01 00:00:00.000000" || date == "86400"){
          return " "
        }
        return moment.unix(date).format("DD/MM/YYYY")
      },
      fixSortingDate(date){
        if( moment.unix(date) == "Invalid date" || date == undefined || moment.unix(date).format('DD/MM/YYYY') == "01/01/0001" || date == "0001-01-01 00:00:00.000000" || date == "No meeting" || date == "Invalid date" || date == "No video appointment") {
          return "86400"
        }
        return date
      },

      // Email modal
      openEmailDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.emailDialog = true;
      },
      // Send email 
      // TODO: make function work properly
      sendMail(appointment) {
        alert('FAKE MAIL SEND TRIGGER FOR THE APPOINTMENT TOWARDS MAIL ' + appointment.ClientEmail);
        console.log("Concept mail poof");        
      },

      // Complete Meeting Modal
      openCompleteAppointmentDialog(appointment) {
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.completeAppointmentDialog = true;
      },
      completeMeeting() {
        AppointmentDataService.update(this.selectedAppointment.key, {
            Finished: '1',
          }).then(response => {
            // Send toast about the appointment is being completed.
            this.output = response.data;
            location.reload();
          })
          .catch(e => {
            this.output = e;
          })
      },
      // Delete modal
      openDeleteAppointmentDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.deleteAppointmentDialog = true;
      },
      deleteAppointment(appointment) {
        AppointmentDataService.delete(appointment.key).then(response => this.output = response
          .data).catch(e => {
            // TODO: Send toast
          this.output = e;
        });    
      },
      // Update Dialog
      openUpdateAppointmentDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.updateAppointmentDialog = true;
      },
      fixDateFormat(value) {
          var split = value.split("/");
          var fixedValue = new Date(+split[2], split[1] - 1, +split[0]);
          return fixedValue;
      },
      UpdateAppointment(appointment) {
        var tempAppointment = JSON.parse(JSON.stringify(appointment))

        // Fixing JavaScript's date stuff
        if(moment(tempAppointment.ReportDate).format('X') == "Invalid date") {
          tempAppointment.ReportDate = this.fixDateFormat(tempAppointment.PlannendWorkDate);
        }
        if(moment(tempAppointment.VideoAppointment).format('X') == "Invalid date") {
          tempAppointment.VideoAppointment = this.fixDateFormat(tempAppointment.VideoAppointment);
        }
        
        var videoTime;
        if(moment(String(tempAppointment.VideoAppointmentTime)).format('HH:mm') == "Invalid date"){
          videoTime = tempAppointment.VideoAppointmentTime;
        }else{
          videoTime = moment(String(tempAppointment.VideoAppointmentTime)).format('HH:mm')
        }
        var videoDate = moment(String(tempAppointment.VideoAppointmentDate)).format('YYYY/MM/DD') != "01/01/0001" ? moment(String(tempAppointment.VideoAppointmentDate)).format('YYYY/MM/DD') : null;
        var VideoAppointment = moment(videoDate + " " + videoTime).format('X');
        
        if(VideoAppointment == "0" || VideoAppointment == undefined) { VideoAppointment = "Invalid date"} 
        if( tempAppointment.GeneralProblem == undefined) { tempAppointment.GeneralProblem = "" }
        AppointmentDataService.update(tempAppointment.key, {
          PlannendWorkDate: moment(tempAppointment.PlannendWorkDate).format('X') == null ? "" : moment(tempAppointment.PlannendWorkDate).format('X'), 
          VideoAppointment: VideoAppointment == "Invalid date" ? "No video appointment" : VideoAppointment,
          GeneralProblem: tempAppointment.GeneralProblem,
        }).then(() => {
          // TODO: Toast this
          this.message = "Afspraak successvol aangepast";
        }).catch(e => {
          // TODO: Toast this
          this.message = "Er is wat fout gegaan; probeer het opnieuw";
          console.log(e)
        })
      },
      cleanAltTel(altTel) {
        if (altTel.startsWith("06-")) {
          const cleanTel = altTel.replace("06-", "+316").replace(/\s/g, "");
          return `http://wa.me/${cleanTel}`
        } else if (altTel.startsWith("06")) {
          const cleanTel = altTel.replace("06", "+316").replace(/\s/g, "");
          return `http://wa.me/${cleanTel}`
        } else {
          return `http://wa.me/${altTel.replace(/\s/g, "")}`
        }
      },
    },
    created() {
      AppointmentDataService.getAll().on("value", this.handleData);
    }
  }
</script>